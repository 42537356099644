// Theme one Colors
// $p-1: #123787;
// $p-2: #1d58d7;
// $p-3: #5173fb;
// $p-4: #7a93ff;
// $p-5: #c2d0ff;
// $p-6: #e5ebff;
// $p-7: #f2f5ff;
// $n-1: #000000;
// $n-2: #666666;
// $n-3: #787878;
// $n-4: #a2a2a2;
// $n-5: #cbcbcb;
// $n-6: #e7e7e7;
// $n-7: #f4f4f4;
// $n-8: #f9f9f9;
// $n-9: #ffffff;
// $s-e-1: #ff5859;
// $s-e-2: #f1494a;
// $s-e-3: #ffc6c6;
// $s-s-1: #28bc97;
// $s-s-2: #289679;
// $s-s-3: #a5e1d1;
// $s-w-1: #ffbb43;
// $s-w-2: #f7ad2a;
// $s-w-3: #f7ce85;

:root {
  --primary-1: #4e0c59;
  --primary-2: #731780;
  --primary-3: #be69bf;
  --primary-4: #cc91cb;
  --primary-5: #d9bdd7;
  --primary-6: #e6d8e4;
  --primary-7: #f9f6f9;
}

//Theme two Colors
$p-1: var(--primary-1);
$p-2: var(--primary-2);
$p-3: var(--primary-3);
$p-4: var(--primary-4);
$p-5: var(--primary-5);
$p-6: var(--primary-6);
$p-7: var(--primary-7);
$n-1: #000000;
$n-2: #666666;
$n-3: #787878;
$n-4: #a2a2a2;
$n-5: #cbcbcb;
$n-6: #e7e7e7;
$n-7: #f4f4f4;
$n-8: #f9f9f9;
$n-9: #ffffff;
$s-e-1: #ff5859;
$s-e-2: #f1494a;
$s-e-3: #ffc6c6;
$s-s-1: #28bc97;
$s-s-2: #289679;
$s-s-3: #a5e1d1;
$s-w-1: #ffbb43;
$s-w-2: #f7ad2a;
$s-w-3: #f7ce85;

// Fonts
$font-base-size: 14px !default;
$font-primary: -apple-system, BlinkMacSystemFont, 'Avenir', sans-serif;

// Miscellaneous
$font-path: '../../fonts/';
$image-path: '../../images';
$icon-path: '../../icons';
