.top__bar {
  height: 50px;
  width: 100%;
  padding: 0px 20px;
  color: $n-9;
  font-weight: 300;
  background-color: $p-2;

  &__child {
    cursor: pointer;
    display: flex;
    padding-right: 10px;

    &::after {
      position: relative;
      content: '>';
      right: -10px;
    }

    &:last-child {
      &::after {
        display: none;
      }
    }
  }

  &__hide {
    font-weight: 100;
    cursor: pointer;
  }
}

.messagebar {
  width: 100%;
  height: 0px;
  top: 66px;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: fit-content;
  position: fixed;
  background-color: $p-2;
  padding: 5px 20px;
  display: flex;
  align-items: center;

  &-message {
    color: $n-9;
  }

  .svg-icon {
    filter: brightness(900%);

    &:hover {
      cursor: pointer;
    }
  }
}
