.i-table {
  padding: 8px;
  min-width: 100%;
  text-align: left;
}

.i-table-bordered .i-th {
  border: 1px solid $n-6;
  cursor: pointer;
}

.i-table-bordered .i-td {
  border: 1px solid $n-6;
}

.i-th {
  padding: 8px 12px;
  background-color: $n-7;
}

// .i-th:last-child div {
//   max-width: 60px;
//   word-break: break-word;
// }

.i-td {
  font-size: 14px;
  line-height: 24px;
  padding: 8px 12px;
  letter-spacing: 0.1px;
}

.i-tr {
  border-bottom: 1px solid $n-6;
}

.i-tr:hover {
  background-color: $n-8;
}

.inventory-table .i-table {
  padding: 8px;
  min-width: 130%;
  text-align: left;

  .i-th {
    p {
      font-weight: 500;
    }
  }
}

.customers-data tbody .i-tr .i-td:nth-of-type(5) {
  text-transform: capitalize;
}

.gloss-table th {
  padding: 5px;
}

.gloss-table {
  font-size: 14px;
  border-collapse: collapse;
}

.gloss-table td {
  padding: 10px;
  max-width: 50px;

  &:focus-visible {
    outline-color: $p-2;
  }
}

.gloss-table tr {
  border-bottom: 1px solid $n-7;
}

.i-table .i-table-bordered {
  min-width: auto;
}

.i-table tr th,
.i-table tr td {
  white-space: nowrap;
}

.i-table .i-table-bordered tr th,
.i-table .i-table-bordered tr td {
  white-space: normal;
}

.dsp-invetories-table thead .i-th:last-child,
.dsp-invetories-table tbody .i-tr .i-td:last-child,
.dsp-invetories-table thead .i-th:nth-last-child(2),
.dsp-invetories-table tbody .i-tr .i-td:nth-last-child(2),
.dsp-invetories-table .i-tr .i-td .form-check {
  display: none;
}

.dsp-invetories-table .i-table .i-table-header .i-th {
  width: 16.6%;
}

.dsp-invetories-table .i-table-bordered .i-tr .i-td {
  width: 14.28%;
}

.traffic-table {
  table-layout: fixed;

  .i-table-header {
    .i-th {
      p {
        font-weight: 500;
      }
    }
  }
}

.traffic-table thead.thead-sticky {
  position: -webkit-sticky;
  position: sticky;
  top: 66px;
  z-index: 1;
}

.table__responsive {
  overflow-x: auto;
}

.fixed__layout__table {
  tr {
    td,
    th {
      white-space: nowrap;
    }
  }

  @media (min-width: 992px) {
    table-layout: fixed;
    width: 100%;
    max-width: none;

    tr {
      td,
      th {
        white-space: nowrap;
      }
    }
  }
}

.traffic-table.fixed__layout__table {
  tr {
    td,
    th {
      white-space: normal;
    }
  }
}

.table-menu {
  width: fit-content;
  background: $n-9;
  position: relative;
  margin-top: -3px;

  &-list {
    z-index: 1;
    background: $n-9;
    min-width: 132px;
    position: absolute;
    //transform: translateX(-115px);
    box-shadow: 0px 0px 2px $n-5;
    top: -4px;
    left: 20px;

    &-item {
      padding: 3px 10px;
      cursor: pointer;

      &:hover {
        background-color: $p-2;
        color: $n-9;

        .svg-icon {
          filter: invert(100%) sepia(100%) saturate(0%) hue-rotate(197deg) brightness(101%)
            contrast(103%);
        }
      }
    }
  }
}

.setting-user-last-child {
  tr {
    td {
      &:last-child {
        display: none;
      }
    }
  }
}

.search-wrap {
  display: flex;
  align-items: center;
  max-width: 250px;
  //position: absolute;
  margin-top: 25px;
  margin-bottom: 15px;
  width: 100%;

  .form-control {
    border-top: 1px solid transparent;
    border-left: 1px solid transparent;
    border-right: 1px solid transparent;
    border-radius: 0;
    padding-left: 0px;
    padding-right: 0px;
    border-bottom: 1px dashed $n-2;
    font-size: 14px;
    padding: 5px;
    max-width: 170px;

    &:focus {
      border-radius: 4px;
      border: 1px solid $p-2;
    }
  }
}

.table-wrap {
  overflow-x: auto;

  .table {
    display: inline-block;
    border-spacing: 0;

    .table-body {
      border: none;
    }

    .tr {
      // height: auto;
      // display: inline-flex;

      :last-child {
        .td {
          border-bottom: 0;
        }
      }
    }

    .th,
    .td {
      margin: 0;
      padding: 0.5rem;
      border-bottom: 1px solid $n-7;
      border-right: 1px solid $n-7;

      &:first-child {
        border-left: 1px solid $n-7;
      }
    }

    th,
    .th {
      padding: 8px 12px;
      background-color: $n-7;
      width: auto;
    }

    td,
    .td {
      padding: 5px 12px;
      width: auto;
      line-height: 14px;
      display: flex !important;
      align-items: center;
      word-break: break-word;
    }

    // &.inventories {

    //     .th:nth-of-type(1),
    //     .th:nth-of-type(2),
    //     .td:nth-of-type(1),
    //     .td:nth-of-type(2) {
    //         width: 40px !important;
    //     }
    // }
  }
}

// Macros table row width truncate
.macros-truncate {
  min-width: auto;
  max-width: auto;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

@media (max-width: 1300px) {
  .inventory-table {
    .table-wrap {
      .tr {
        .th:nth-child(4),
        .td:nth-child(4) {
          width: 100px !important;
          flex: 100 0 auto !important;
        }
        .th:nth-child(5),
        .td:nth-child(5) {
          width: 100px !important;
          flex: 100 0 auto !important;
        }
        .th:nth-child(6),
        .td:nth-child(6) {
          width: 100px !important;
          flex: 100 0 auto !important;
        }
        .th:nth-child(7),
        .td:nth-child(7) {
          width: 100px !important;
          flex: 100 0 auto !important;
        }
      }
    }
  }
}

.payment-method-truncate {
  min-width: 40px;
  max-width: 150px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.editable-disbale {
  .table-input {
    border: none;
    padding-left: 0;
    cursor: default;
    pointer-events: none;
  }
  .table-body {
    .tr:last-child {
      .td:nth-of-type(3) {
        input {
          visibility: hidden;
        }
      }
    }
  }
}
.admin-platform-cost {
  .table-input {
    padding-left: 0.375rem;
    height: 25px;
    &.error {
      border-color: $s-e-2 !important;
    }
  }
  .editable {
    .table-input {
      border: 1px solid $n-4;
    }
  }
  .input-error {
    border-color: $s-e-2 !important;
    max-width: 160px;
  }
  .table-body {
    .tr:last-child {
      .td:nth-of-type(3) {
        input {
          visibility: hidden;
        }
      }
    }
  }
  tr {
    td {
      border-color: $n-7;
    }
  }
}
.table-wrap {
  .th.sticky-column,
  .td.sticky-column {
    position: -webkit-sticky; // for safari
    position: sticky;
    right: 0px;
    // top: 0px; -> doesn't work in Safari 11
    /*
        thanks to https://github.com/jonjohnjohnson
        explanation: https://github.com/w3c/csswg-drafts/issues/865
      */
    z-index: 2;
    display: inline-block;
    box-sizing: border-box;
    width: 150px;
    border-left: 1px solid $n-7;
  }
  .td.sticky-column {
    background-color: $n-9;
  }
}
.table-header {
  position: sticky;
  top: 0;
  z-index: 0;
}

.infy-table {
  .table {
    &-header {
      position: sticky;
      top: 0;
      z-index: 1;
      background: $n-9;
      th {
        padding: 8px 12px;
        background-color: $n-7;
        border-bottom: 1px solid $n-7;
        border-right: 1px solid $n-7;
        &.th_included,
        &.th_assigned {
          position: -webkit-sticky; // for safari
          position: sticky;
          right: 0px;
          // top: 0px; -> doesn't work in Safari 11
          /*
              thanks to https://github.com/jonjohnjohnson
              explanation: https://github.com/w3c/csswg-drafts/issues/865
            */
          z-index: 1;
          display: inline-block;
          box-sizing: border-box;
          width: 150px;
          border-left: 1px solid $n-7;
        }
        &.sort_false {
          cursor: default;
          pointer-events: none;
        }
        &.sort_true {
          cursor: pointer;
          pointer-events: visible;
        }
      }
    }
    tbody {
      border: none;
      tr {
        td {
          border-bottom: 1px solid $n-7;
          border-right: 1px solid $n-7;
          .sb-avatar + span {
            font-weight: 100;
          }

          &.payment_proof {
            span {
              display: none;
            }
          }
          &.included,
          &.assigned {
            position: -webkit-sticky; // for safari
            position: sticky;
            right: 0px;
            // top: 0px; -> doesn't work in Safari 11
            /*
              thanks to https://github.com/jonjohnjohnson
              explanation: https://github.com/w3c/csswg-drafts/issues/865
            */
            z-index: 0;
            display: inline-block;
            box-sizing: border-box;
            width: 150px;
            border-left: 1px solid $n-7;
            background: $n-9;
          }

          &.action {
            .dropdown {
              .dropdown-toggle {
                width: fit-content;
                padding: 0px;
                margin: 0px;
                background-color: transparent;
                height: 25px !important;
                min-width: 25px !important;
                max-width: 25px !important;
                border-radius: 50%;

                &:focus {
                  box-shadow: none;
                }
              }

              .dropdown-toggle::after {
                display: none;
              }
            }
          }
        }
      }
    }
  }
  &.invite-customer {
    tr {
      .td_selection,
      th:first-child {
        width: 40px !important;
        padding: 8px 20px;
      }
    }
    tbody {
      tr {
        .td_selection {
          line-height: 25px;
        }
        &.disable {
          pointer-events: none;
          opacity: 0.5;
        }
      }
    }
  }
}

/* Admin traffic table */
.admin-traffic {
  &-tr {
    &.expanded {
      background-color: $n-6;
    }

    &.subrow {
      background-color: $n-8;
    }
  }

  &-tr:hover {
    background-color: $n-6;
  }

  &-td {
    padding: 6px 10px;
  }
}

/* platform cost table */
.platformcost-table {
  .infy-table {
    height: auto;
  }
}
.platformcost-table,
.admin-platform-cost {
  .table-header {
    .tr,
    tr {
      .th,
      th {
        pointer-events: none;
        cursor: default;
      }
    }
  }
}
.admin-platform-cost {
  .editable-disbale {
    .table-header {
      .tr {
        &:first-child {
          th {
            font-weight: 100;
          }
        }
      }
    }
  }
}

.tab-container {
  .tab-content {
    margin-top: 0px;
  }
}

.td {
  .icon-onhover {
    opacity: 0;
  }

  &:hover {
    .icon-onhover {
      opacity: 1;
    }
  }
}

.spo-inventories {
  .table {
    .tr {
      .th {
        &:first-child {
          display: none;
        }
      }
    }
    .table-body {
      .tr {
        .td {
          &:first-child {
            display: none !important;
          }
        }
      }
    }
  }

  .disable {
    pointer-events: none;
    opacity: 0.5;
  }
}

.video_share_string {
  span {
    display: none;
  }
}

// Infy Core table
.coretable {
  .table {
    &-header {
      position: sticky;
      top: 0;
      z-index: 1;
      background: $n-9;
      th {
        padding: 8px 12px;
        background-color: $n-7;
        border-bottom: 1px solid $n-7;
        border-right: 1px solid $n-7;
        &.th_included,
        &.th_assigned {
          position: -webkit-sticky; // for safari
          position: sticky;
          right: 0px;
          z-index: 1;
          display: inline-block;
          box-sizing: border-box;
          width: 150px;
          border-left: 1px solid $n-7;
        }
        &.sort_false {
          cursor: default;
          pointer-events: none;
        }
        &.sort_true {
          cursor: pointer;
          pointer-events: visible;
        }
      }
    }
    tbody {
      border: none;
      tr {
        td {
          border-bottom: 1px solid $n-7;
          border-right: 1px solid $n-7;
          .sb-avatar + span {
            font-weight: 100;
          }

          &.payment_proof {
            span {
              display: none;
            }
          }
          &.included,
          &.assigned {
            position: -webkit-sticky; // for safari
            position: sticky;
            right: 0px;
            z-index: 0;
            display: inline-block;
            box-sizing: border-box;
            width: 150px;
            border-left: 1px solid $n-7;
            background: $n-9;
          }

          &.action {
            .dropdown {
              .dropdown-toggle {
                width: fit-content;
                padding: 0px;
                margin: 0px;
                background-color: transparent;
                height: 25px !important;
                min-width: 25px !important;
                max-width: 25px !important;
                border-radius: 50%;

                &:focus {
                  box-shadow: none;
                }
              }

              .dropdown-toggle::after {
                display: none;
              }
            }
          }
        }
        &:hover {
          background-color: $n-8;
        }
      }
    }
  }
}

// Videos Library Video Column
.video-col {
  cursor: pointer;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;

  &:hover {
    .play-icon {
      visibility: visible;
      transition: 0.8s;
    }
  }

  .play-icon {
    position: absolute;
    visibility: hidden;
  }

  .duration {
    position: absolute;
    background-color: #fff;
    padding: 3px 2px;
    width: 100%;
    opacity: 0.8;
    color: black;
    bottom: 0;
    text-align: center;
  }
}
