// Mobile View
.mobile-frame {
  position: relative;
  width: 340px;
}

.mobile-frame > img {
  position: relative;
  width: 345px;
  margin: 0 auto;
  z-index: 1;
}

.mobile-frame-box {
  position: absolute;
  top: 40px;
  left: 11px;
  z-index: 2;
  width: calc(100% - 24px);
  border-bottom-left-radius: 36px;
  border-bottom-right-radius: 26px;
  overflow-x: hidden;
  overflow-y: auto;
  height: 658px;
  padding: 0px 0px 0px 5px;
}

.usatoday-mobile {
  .main-content {
    margin-top: 15px;

    &-title {
      font-weight: 300;
      letter-spacing: 0.1px;
    }
  }

  .single-content {
    p {
      letter-spacing: 0.2px;
    }

    img {
      object-fit: cover;
    }

    h5 {
      font-size: 14px;
      line-height: 18px;
    }
  }
}

// Tablet View
.tablet-frame {
  position: relative;
  width: 700px;
}

.tablet-frame > img {
  position: relative;
  width: 700px;
  margin: 0 auto;
  z-index: 1;
}

.tablet-frame-box {
  position: absolute;
  top: 25px;
  left: 24px;
  z-index: 2;
  width: calc(100% - 47px);
  border-radius: 3px;
  overflow-x: hidden;
  overflow-y: auto;
  height: 407px;
  padding: 5px;
}

.usatoday-tablet {
  .gnt_bc {
    min-width: 100%;
    padding-top: 20px;
    background: linear-gradient(150deg, #aa1e23, 50%, #f03c4b);
  }

  .left-content {
    margin-top: 10px;
  }

  .single-content {
    p {
      letter-spacing: 0.2px;
    }

    img {
      object-fit: cover;
    }

    h5 {
      font-size: 14px;
      line-height: 18px;
    }
  }
}

//Desktop View
.desktop-frame {
  position: relative;
  width: 800px;
}

.desktop-frame > img {
  position: relative;
  width: 830px;
  margin: 0 auto;
  z-index: 1;
}

.desktop-frame-box {
  position: absolute;
  top: 18px;
  left: 75px;
  z-index: 2;
  width: calc(100% - 122px);
  border-radius: 3px;
  overflow-x: hidden;
  overflow-y: auto;
  height: 438px;
  padding: 5px;
}

.usatoday-desktop {
  .gnt_bc {
    min-width: 100%;
    padding-top: 20px;
    background: linear-gradient(150deg, #691464, 50%, #aa50e6);
  }

  .en-nav {
    display: flex;
    padding: 10px 0px;

    &-left {
      width: 25%;
    }

    &-right {
      width: 75%;
      padding: 0px 30px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-weight: 200;
      color: #787878;
    }

    &-btn {
      font-size: 12px;
    }
  }

  .left-content {
    width: 359px;
  }

  .right-content {
    width: 300px;
    margin-left: 10px;
  }

  .single-content {
    p {
      letter-spacing: 0.2px;
    }

    img {
      object-fit: cover;
    }

    h5 {
      font-size: 14px;
      line-height: 18px;
    }
  }
}

// Advertisement Section
.advertisement {
  background-color: $n-6;
  padding: 5px;

  .label {
    color: $n-3;
    font-size: 12px;
  }
}
