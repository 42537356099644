input[type='checkbox'] {
  position: relative;
  border: 1px solid $n-1;
  border-radius: 2px;
  background: none;
  cursor: pointer;
  line-height: 0;
  //margin: 0 .6em 0 0;
  outline: 0;
  padding: 0 !important;
  vertical-align: text-top;
  height: 16px;
  width: 16px;
  -webkit-appearance: none;
  opacity: 0.5;

  &:hover {
    opacity: 1;
  }

  &:checked {
    background-color: $p-2;
    opacity: 1;
    border: none;
  }

  &:focus {
    box-shadow: none;
    border-color: $p-2;
  }

  &::before {
    content: '';
    position: absolute;
    right: 50%;
    top: 50%;
    width: 5px;
    height: 9px;
    border: solid $n-9;
    border-width: 0 2px 2px 0;
    margin: -1px -1px 0 -1px;
    transform: rotate(45deg) translate(-50%, -50%);
    z-index: 2;
  }
}

.top__bar {
  [type='checkbox'] {
    background: $n-9;
    border: none;

    &:checked {
      background-color: $n-9;
      opacity: 1;
      border: none;
      width: 16px;
      height: 16px;

      &::before {
        content: '';
        position: absolute;
        right: 50%;
        top: 50%;
        width: 5px;
        height: 9px;
        border: solid $p-2;
        border-width: 0 2px 2px 0;
        margin: -1px -1px 0 -1px;
        transform: rotate(45deg) translate(-50%, -50%);
        z-index: 2;
      }
    }
  }

  label,
  p {
    font-weight: 200;
  }

  .progress {
    height: 8px;
    background-color: $n-9;

    .progress-bar {
      background-color: $p-4;
      color: $p-4;
    }
  }
}
