.invetory-card-s {
    .card {
        width: 100%;
        height: 300px;

        background-image: linear-gradient(90deg,
                $n-7 0px,
                rgba(229, 229, 229, 0.8) 40px,
                $n-7 80px);

        background-size: 600px;
        animation: shine-lines 2s infinite ease-out;
    }
}