.deal-performance {
  .overview-text {
    display: none;
  }

  .overview-date {
    position: absolute !important;
    right: 70px;
    top: 30px;
  }
}

.ssp-deal-main {
  margin-top: 30px;
  justify-content: space-between;
}
