.dropdown-menu.show {
  padding: 0px;
  border-color: $p-2;
  transform: translate3d(-12px, 40px, 0px) !important;
  overflow: hidden;

  .dropdown-item {
    padding: 10px 8px;
    font-weight: 200;
    margin: 0px;
    font-size: 14px;
    line-height: 24px;

    &:focus,
    &:hover {
      background-color: $p-7;
    }

    &:active {
      color: $n-1;
    }
  }

  // For Finance & Payout table action cell
  &.table-action-cell {
    border: transparent;
    box-shadow: 0 0 2px $n-5;
    border-radius: 0px;

    .dropdown-item {
      font-weight: inherit;
      padding: 3px 8px;

      &:focus,
      &:hover {
        background-color: $p-2;
        color: $n-9;
      }
    }
  }
}

.macros-dropdown {
  .col {
    max-width: 238px;
  }
}

.dropdown-sort {
  padding: 0px;
  // height: 16px;
  width: 16px; // add for sidebar filter button
  &.show {
    .dropdown-toggle {
      background-color: transparent;
      box-shadow: none;
    }
  }
  .dropdown-toggle {
    padding: 0px;
    height: 20.23px; //21.23px
    min-width: 16px; //add for same as remove
    max-width: 16px; //add for same as remove
    // width: 16px; //remove for sidebar filter button
    background-color: transparent;
    box-shadow: none !important;
    &:focus {
      background-color: transparent;
      box-shadow: none;
    }
  }
  .svg-icon {
    margin-top: -5px;

    &:hover {
      path {
        stroke: $n-1;
      }
    }

    path {
      stroke: $n-3;
      stroke-width: 1.7px;
    }
  }
  .dropdown-menu.show {
    padding: 0px;
    transform: translate3d(-1px, 22px, 0px) !important;
    overflow: hidden;
    border-radius: 0px;
    box-shadow: 0 0 2px $n-5;
    border: none;

    .dropdown-item {
      padding: 3px 10px;
      color: $n-1;
      font-weight: 500;

      &.active {
        color: $n-9;
        &:hover {
          background-color: $p-2;
          color: $n-9;
        }
      }
      &:hover,
      &:focus {
        color: $n-1;
      }
    }
    .dropdown-title {
      padding: 10px 10px;
      font-size: 12px;
      font-weight: 200;
    }
  }
}

// Custom Dropdown
.custom-dropdown-input-container {
  position: relative;

  input {
    &:disabled {
      cursor: default;
    }
  }

  &:focus-visible {
    outline: transparent;
  }
}

.custom-dropdown-list-container {
  position: absolute;
  width: 100%;
  z-index: 1;
}

.custom-dropdown-list-container.top {
  bottom: 100%;
}

.custom-dropdown-list-container.bottom {
  top: 100%;
}

.dropdown-icon {
  position: absolute;
  top: 50%;
  right: 5px;
  transform: translateY(-50%);
  background-color: $n-9;
}

.dropdown-icon .disabled {
  pointer-events: none;
  cursor: default;
}

.custom-dropdown-container {
  display: inline-block;
  position: relative;
  width: 100%;
}

.custom-dropdown-input {
  width: 100%;
  padding: 8px;
  border: 1px solid $n-4;
  border-radius: 4px;
  box-shadow: none;
  cursor: pointer;

  &:focus,
  &:focus-visible {
    outline: none;
    border: 1px solid $p-2;
  }
}

.custom-dropdown-list {
  // position: absolute; // This is comment out for dropdown position top/bottom
  top: 100%;
  left: 0;
  z-index: 1;
  width: 100%;
  min-height: auto;
  max-height: 450px;
  background-color: $n-9;
  overflow-y: auto;
  // border: 1px solid #ccc;
  margin-top: 6px;
  border-radius: 4px;
  box-shadow: 0 0 0 1px hsla(0, 0%, 0%, 0.1), 0 4px 11px hsla(0, 0%, 0%, 0.1);
  box-sizing: border-box;
}

.custom-dropdown-list-item {
  color: $n-1;
  display: block;
  font-size: inherit;
  padding: 8px 12px;
  cursor: pointer;
  background-color: $n-9;
  width: 100%;

  label {
    color: $n-1 !important;
  }

  input[type='search'] {
    width: 100%;
    height: 22px;
    border: none;
    background-color: $p-7;

    &:focus {
      outline: none;
    }
  }
}

.custom-dropdown-list-item:hover {
  background-color: $n-7;
}

.custom-dropdown-list-item.selected {
  background-color: $n-6;
}

.custom-dropdown-list-item-checkbox {
  margin-right: 8px;
}

// Macros dropdown select all checkbox row
.macros-selectall {
  .custom-dropdown-list-item:hover {
    background-color: transparent;
  }
}

.macro-dropdown {
  .custom-dropdown-list-container {
    top: 100%;
    left: 0;
    z-index: 1;
    width: 100%;
    min-height: auto;
    max-height: 450px;
    background-color: $n-9;
    overflow-y: auto;
    margin-top: 6px;
    border-radius: 4px;
    box-shadow: 0 0 0 1px hsla(0deg, 0%, 0%, 0.1), 0 4px 11px hsla(0deg, 0%, 0%, 0.1);
    box-sizing: border-box;
  }

  .list-group {
    border: none;
    border-radius: 0px;
    box-shadow: none;

    &-item {
      border-left: none;
      border-top: none;
      border-right: none;
      padding: 8px 10px;

      &:last-child {
        border: none;
      }

      &.active {
        background-color: $p-2;
        border: none;
      }
    }
  }
}
