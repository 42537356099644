.header {
  z-index: 1;
  width: 100%;
  position: sticky;
  top: 0;
  background-color: $n-9;
  justify-content: space-between;
  // box-shadow: 0px 4px 25px rgba(0, 0, 0, 0.06);
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);

  &-menu {
    padding: 8px 14px;
  }

  .col {
    margin-top: 0;
  }

  &__left {
    min-width: 340px;

    a {
      display: inline-block;
      max-width: 150px;
    }
  }

  &__middle {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    gap: 30px;

    a {
      color: $n-1;
      font-weight: 200;
      font-size: 16px;

      &.active {
        color: $p-2;
      }
    }
  }

  &__right {
    //padding: 0px 16px;

    &__wrap {
      padding: 0px 15px 0px 15px;
      display: flex;
      gap: 15px;
      align-items: center;

      @media (min-width: 992px) {
        padding: 0px 32px 0px 32px;
        display: flex;
        gap: 36px;
      }

      &__box {
        display: flex;
        flex-direction: column;
        align-items: flex-start;

        &__lable {
          font-size: 14px;
          color: $n-4;
        }

        &__value {
          font-size: 15px;
          font-weight: 200;

          @media (min-width: 992px) {
            font-size: 16px;
          }
        }
      }
    }

    &__menu {
      height: 50px;
      padding: 5px 10px;
      border: 1px solid $p-2;
      border-radius: 4px;
      position: relative;
      cursor: pointer;
      display: flex;
      justify-content: space-between;
      margin-left: 10px;

      &:hover {
        background-color: $p-7;
      }

      &__name {
        min-width: fit-content;
        margin-right: 12px;

        p {
          font-weight: 200;
        }
      }

      &__img {
        min-width: fit-content;
      }

      &__item {
        left: 0px;
        top: 100%;
        min-width: 100%;
        position: absolute;
        background-color: $n-9;
        border-bottom-left-radius: 4px;
        border-bottom-right-radius: 4px;
        border: 1px solid $p-2;
        margin-top: -3px;
        border-top: none;
        margin-left: -1px;
        margin-right: -1px;
        right: 0;
        overflow: hidden;

        p {
          padding: 10px 8px;
          margin: 0px;
          font-weight: 200;

          &:hover {
            background-color: $p-7;
            //color: $n-9;
          }
        }
      }
    }
  }
}
