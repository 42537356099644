.sspdeal-performace {
  .overview-text {
    display: none;
  }
  .overview-date {
    position: absolute !important;
    right: 10px;
    top: 25px;
  }
}
