* {
  font-family: 'Avenir', sans-serif;
}

// html {
//     overflow: scroll;
// }

::-webkit-scrollbar {
  width: 0px;
  background: transparent;
  /* make scrollbar transparent */
}

h1 {
  margin: 0;
  font-size: 28px;
  font-weight: 300;
  line-height: 32px;
  letter-spacing: 0.2px;
}

h2 {
  margin: 0;
  font-size: 24px;
  font-weight: 200;
  line-height: 26px;
  letter-spacing: 0.2px;
}

h3 {
  margin: 0;
  font-size: 20px;
  font-weight: 200;
  line-height: 26px;
  letter-spacing: 0.2px;
}

h4 {
  margin: 0;
  font-size: 18px;
  font-weight: 200;
  line-height: 24px;
  letter-spacing: 0.2px;
}

h5 {
  margin: 0;
  font-size: 16px;
  font-weight: 200;
  line-height: 24px;
  letter-spacing: 0.1px;
}

h6 {
  margin: 0;
  font-size: 12px;
  font-weight: 200;
  line-height: 24px;
  letter-spacing: 0.1px;
}

p {
  margin: 0;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: 0.1px;
}

label {
  margin: 0;
  font-size: 12px;
  line-height: 18px;
  letter-spacing: 0.1px;
}

a {
  color: $n-1;
  text-decoration: none;

  &:hover {
    color: $p-1;
  }
}

.text-primary {
  color: $p-2 !important;
}

.content {
  max-width: calc(100vw - 56px);
}

.overlay {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 2;
  width: 100%;
  height: 100%;
  position: fixed;
  background-color: rgba(0, 0, 0, 0.3);
}

.modal-content {
  max-width: 700px;
}

.context::before {
  content: '';
  width: 16px;
  height: 16px;
  display: block;
  position: absolute;
  background-color: transparent;
}

.context::before {
  top: -1px;
  right: 0px;
}

.image-hover > div {
  display: none;
  cursor: pointer;
}

.image-hover:hover > div {
  display: block !important;
}

.light-shadow {
  box-shadow: rgb(0 0 0 / 4%) 0px 0px 8px;
}

.svg-icon {
  flex-shrink: 0;
  filter: invert(49%) sepia(0%) saturate(928%) hue-rotate(200deg) brightness(95%) contrast(96%);

  &:hover {
    filter: brightness(0%);
    //filter: invert(84%) sepia(41%) saturate(4888%) hue-rotate(238deg) brightness(70%) contrast(90%);
    cursor: pointer;
  }
}

.s-svg-icon {
  filter: invert(49%) sepia(0%) saturate(928%) hue-rotate(200deg) brightness(95%) contrast(96%);
  cursor: default;
}

.spinner-wrap {
  color: $p-2;
}

.sb-avatar {
  &__text {
    background: $p-2 !important;
  }
}

.react-joyride__overlay {
  pointer-events: none !important;
}

// .data-not-available {
//   .header {
//     position: fixed;
//   }
//   & + .main-container {
//     .content {
//       padding-top: 150px !important;
//     }
//     .barside {
//       top: 115px;
//     }
//   }
// }

// .top-bar-view + .header + .main-container {
//   .barside {
//     top: 115px;
//   }
// }

.view-top-bar + .main-container {
  .barside,
  .barside-panel {
    top: 115px !important;
  }
  .content {
    .sidebar-collapsed,
    .main__container {
      height: calc(100vh - 116px);
    }
  }
}

.header {
  & + .main-container {
    .setting + .barside {
      top: 69px;
    }
  }
}

.main-container {
  .on-bording + .content.d-flex {
    display: none !important;
  }
}

.disabled {
  opacity: 0.5;
  pointer-events: none;
}
