.login__wrap {
  display: flex;
  align-items: center;

  &__left {
    display: flex;
    width: fit-content;
    flex-direction: column;
    justify-content: center;
    height: calc(100vh - 66px);

    &__box {
      margin: 72px;
      row-gap: 16px;
      width: 380px;
      display: flex;
      flex-direction: column;
    }
  }

  &__right {
    background-size: cover;
    height: calc(100vh - 66px);
    width: calc(100vw - 450px);
    background-position: center center;
    background-repeat: no-repeat;
    background-image: url('#{$image-path}/bg-2.png');

    &.genuin {
      background-image: url('#{$image-path}/bg-genuin.png');
    }
  }

  @media (max-width: 991px) {
    overflow: hidden;

    &__left {
      background-size: cover;
      height: calc(100vh - 66px);
      width: 100%;
      background-position: center center;
      background-repeat: no-repeat;
      background-image: url('#{$image-path}/bg-2.png');

      &.genuin {
        background-image: url('#{$image-path}/bg-genuin.png');
      }

      &__box {
        margin: 0px;
        padding: 30px;
        margin: 0 auto;
        max-width: 335px;
      }
    }

    &__right {
      display: none;
    }
  }
}
