video::-webkit-media-controls-start-playback-button {
  display: none;
}

// button.hidden {
//   display: none;
// }

// video:hover + button {
//   display: block;
// }

// .video-container .play-icon {
//   position: absolute;
//   top: 50%;
//   left: 50%;
//   transform: translate(-50%, -50%);
//   opacity: 0;
//   transition: opacity 0.3s ease-in-out;
// }

// .video-container:hover .play-icon {
//   opacity: 1;
//   height: 470px;
// }

.vp {
  display: flex;
  height: 600px;
  width: 300px;
  flex-direction: row;
  align-items: center;

  video::-webkit-media-controls-fullscreen-button {
    display: none;
  }
}

.vp_player {
  height: 600px;
  width: 300px;
  border: solid 1px $n-7;
  position: relative;
  overflow: hidden;
}

.vp_player .video {
  height: 600px;
  width: 300px;
}

.vp_bgimg {
  background-color: grey;
  height: 100%;
  width: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  filter: blur(20px);
  top: 16px;
  left: 0px;
  position: absolute;
  object-fit: cover;
}

.vp_overlay {
  position: absolute;
  z-index: 2;
  height: 100%;
  width: 100%;
  user-select: none;

  .icon_btn {
    cursor: pointer;
  }

  &_topbar {
    width: 100%;
    position: absolute;

    .logo {
      display: flex;
      justify-content: end;

      &_agency {
        height: 28px;
        width: auto;
      }

      &_adreel {
        height: 16px;
        width: auto;
        background-color: unset;
      }
    }

    .topbar_icons {
      display: flex;
      padding: 10px 10px;
      justify-content: space-between;
    }
  }

  &_rightbar {
    right: 15px;
    bottom: 18px;
    position: absolute;
    display: flex;
    flex-direction: column;
    gap: 15px;
    align-items: center;
  }

  &_bottombar {
    position: absolute;
    bottom: 10px;
    left: 15px;

    .owner {
      display: flex;
      align-items: center;
      gap: 10px;
      margin-bottom: 10px;

      &__img {
        height: 36px;
        width: 36px;
        cursor: pointer;

        img {
          width: 100%;
          height: 100%;
          border-radius: 50%;
          overflow: hidden;
          object-fit: cover;
          background-color: #d1d1d1;
        }
      }

      &__name {
        font-size: 14px;
        font-weight: 600;
        line-height: 16px;
        color: $n-9;
        letter-spacing: 0.5px;
        cursor: pointer;
      }
    }

    .hashtag {
      max-width: 220px;
      overflow: hidden;
      margin-bottom: 5px;
      font-size: 15px;
      line-height: 19px;
      color: $n-9;
      font-weight: 600;
      letter-spacing: 0.5px;

      &__animation {
        -webkit-animation: scrolling-left1 20s linear infinite;
        animation: scrolling-left1 20s linear infinite;
        display: inline-block;
      }
    }

    .cta {
      border-radius: 8px;
      transition: background-color 0.3s ease 0s, backdrop-filter 0.3s ease 0s;
      background-color: rgba(0, 0, 0, 0.4);
      backdrop-filter: blur(15px);
      width: 220px;

      &__adcopy {
        &__text {
          font-size: 14px;
          margin-bottom: 8px;
          font-weight: 600;
          color: white;
          line-height: 19px;
          background-color: unset;
          text-overflow: ellipsis;
          padding: 12px 12px 0px 10px;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          overflow: hidden;
          word-break: break-all;
        }
      }

      &__button {
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-radius: 8px;
        color: $n-9;
        height: 46px;
        cursor: pointer;

        p {
          padding-left: 16px;
          font-weight: 600;
          line-height: 20px;
          font-size: 16px;
        }

        .arrow {
          margin-right: 12px;
        }
      }
    }

    @keyframes scrolling-left1 {
      0% {
        -webkit-transform: translateX(0);
        transform: translateX(0);
      }

      100% {
        -webkit-transform: translateX(-100%);
        transform: translateX(-100%);
      }
    }
  }

  &_newbottombar {
    position: absolute;
    bottom: 0;
    width: 100%;

    .bottom_inner_container {
      display: flex;
      align-items: flex-end;
      justify-content: space-between;
      padding: 8px 8px 0px;
    }

    .left {
      .owner {
        display: flex;
        align-items: center;
        gap: 10px;
        margin-bottom: 10px;

        &__img {
          height: 28px;
          width: 28px;
          cursor: pointer;

          img {
            width: 100%;
            height: 100%;
            border-radius: 50%;
            overflow: hidden;
            object-fit: cover;
            background-color: #d1d1d1;
          }
        }

        &__name {
          margin-top: 3px; // external
          font-size: 12px;
          font-weight: 700;
          line-height: 16px;
          color: $n-9;
          letter-spacing: 0.5px;
          cursor: pointer;
        }
      }
    }

    .right {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 10px;
      margin-bottom: 5px;
    }

    .cta {
      border-radius: 8px;
      transition: background-color 0.3s ease 0s, backdrop-filter 0.3s ease 0s;
      background-color: rgba(0, 0, 0, 0.4);
      backdrop-filter: blur(15px);

      &__adcopy {
        &__text {
          font-size: 14px;
          font-weight: 600;
          margin-bottom: 8px;
          color: white;
          line-height: 19px;
          background-color: unset;
          text-overflow: ellipsis;
          padding: 12px 12px 0px 10px;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          overflow: hidden;
          word-break: break-word;
        }
      }

      &__button {
        display: flex;
        justify-content: space-between;
        align-items: center;
        color: $n-9;
        height: 32px;
        cursor: pointer;

        p {
          font-size: 14px;
          line-height: 20px;
          // font-weight: 600;
          font-weight: lighter; // external
          padding-left: 16px;
          color: white;
          background-color: unset;
        }

        .arrow {
          margin-right: 12px;
        }
      }
    }
  }
}
