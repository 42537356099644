.sidebar-collapsed {
  min-width: 352px;
  max-width: 352px;
  display: flex;
  padding-top: 20px;
  padding-bottom: 20px;
  padding-left: 25px;
  padding-right: 0px;
  flex-direction: column;
  height: calc(100vh - 66px);
  overflow-y: auto;
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.04);
}

.sidebar-list {
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  padding-right: 5px;
}

.sidebar-expanded {
  z-index: 3;
  width: fit-content;
  display: flex;
  overflow-x: hidden;
  overflow-y: hidden;
  position: absolute;
  padding-top: 16px;
  padding-left: 16px;
  padding-right: 16px;
  flex-direction: column;
  height: calc(100vh - 66px);
  background-color: $n-9;
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.04);
}

.sidebar-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.sidebar-list-wrap {
  padding-right: 5px;
  overflow-y: auto;

  .sidebar-list {
    padding-right: 20px;
  }
}

.sidebar-row-first,
.sidebar-row-secound {
  padding-right: 25px;
}

.sidebar-col {
  display: flex;
  flex-direction: column;
  justify-content: center;

  .search-input {
    outline: 0px;
    width: 100%;
    font-size: 14px;
    border-top: none;
    border-right: none;
    border-bottom: 1px dashed $n-1;
    border-left: none;
    border-image: initial;
    padding: 0px 10px;
    max-width: 138px;
  }
}

.expand__sidebar {
  margin-top: 16px;

  &__header {
    display: flex;
    justify-content: space-between;

    &__item {
      cursor: pointer;
      display: flex;
      justify-content: space-between;

      &__sort {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        column-gap: 5px;
        position: relative;
        width: 100px;

        .svg-icon {
          position: absolute;
          top: 1px;
        }
      }

      &:first-child {
        min-width: 300px;
      }

      &.name {
        .svg-icon {
          left: 40px;
        }
      }

      &.creates {
        .svg-icon {
          left: 55px;
        }
      }

      &.impressions {
        .svg-icon {
          left: 80px;
        }
      }

      &.earnings {
        .svg-icon {
          left: 60px;
        }
      }

      &.fill-rate {
        .svg-icon {
          left: 56px;
        }
      }

      &.spending {
        .svg-icon {
          left: 65px;
        }
      }

      &.floor-price {
        .svg-icon {
          left: 75px;
        }
      }

      &.status {
        .svg-icon {
          left: 45px;
        }
      }
    }
  }

  // .list {
  //     &-avatar {
  //         background: $p-2  !important;
  //     }
  // }
}

.filter__sidebar {
  display: flex;
  flex-direction: column;
  margin-top: 20px;
  row-gap: 10px;

  &__filed {
    margin-bottom: 5px;
    margin-right: 25px;

    &__groupe {
      max-width: 115px;

      @media (min-width: 992px) {
        max-width: 140px;
      }
      @media (min-width: 13001px) {
        max-width: 140px;
      }
    }
    @media (min-width: 992px) {
      &.floor__price {
        flex-direction: column;
        .filter__sidebar__filed__groupe {
          margin-bottom: 20px;
        }
      }
    }

    @media (min-width: 1301px) {
      &.floor__price {
        flex-direction: row;
        .filter__sidebar__filed__groupe {
          margin-bottom: 0px;
        }
      }
    }
  }

  &__label,
  label {
    // color: $n-4;
    margin-bottom: 5px;
    font-size: 14px;
  }
}

.siderbar-filter-menu {
  margin-left: 8px;
  width: fit-content;
  background: $n-9;
  // border-left: 1px solid rgb(29, 88, 215);
  // border-right: 1px solid rgb(29, 88, 215);
  // border-top: 1px solid rgb(29, 88, 215);
  position: relative;

  svg {
    filter: brightness(0%);
    cursor: pointer;
  }

  &-list {
    z-index: 1;
    background: $n-9;
    min-width: 168px;
    position: absolute;
    //border: 1px solid rgb(29, 88, 215);
    transform: translateX(-151px);
    box-shadow: 0px 0px 2px $n-5;

    p {
      padding: 3px 10px;

      &:hover {
        background-color: $p-2;
        color: $n-9;
      }
    }

    &-item {
      color: $n-1;

      &.active {
        color: $n-9;
        background-color: $p-2;
      }
    }
  }
}

.dsp-inventory {
  .filter__sidebar {
    &__filed {
      &:nth-of-type(1) {
        order: 1;
      }

      &:nth-of-type(2) {
        order: 3;

        .form-group {
          padding: 7px 0px;
        }
      }

      &:nth-of-type(3) {
        order: 2;
      }

      &:nth-of-type(8) {
        order: 6;
      }

      &:nth-of-type(7) {
        order: 5;
      }

      &:nth-of-type(6) {
        order: 4;
      }

      &:nth-of-type(5) {
        order: 7;
      }

      &:nth-of-type(9) {
        order: 8;
        padding: 25px 0px;
      }
    }
  }
}

.svg-icon {
  &.expand {
    path {
      stroke-width: 1.5px;
    }
  }
}

/********** New Sidebar CSS START **********/
.newsidebar {
  min-width: 352px;
  max-width: 352px;
  // backdrop-filter: blur(6px);
  // -webkit-backdrop-filter: blur(6px);
  padding: 20px 0 20px 25px;
  //border-right: 2px solid rgba(255, 255, 255, 0.09);
  transition: 0.4s ease-in-out;
  z-index: 1;
  box-shadow: 0 0 8px rgb(0 0 0 / 4%);
  position: fixed;
  background: $n-9;
  margin-top: 1px;
  .sidebar-row-first {
    h3 {
      line-height: 40px;
    }
  }
  &.open {
    min-width: 1000px;
    z-index: 3;
    position: fixed;
    background-color: $n-9;

    .compact-card {
      display: none;
    }
    .toggle {
      .svg-icon {
        transform: rotateY(180deg);
      }
    }
    .sidebar-row-first {
      height: 30px;
      margin-bottom: 20px;
    }
    .sidebar-row-secound {
      position: absolute;
      top: 0px;
      margin-left: 105px;
      display: inherit;
      &.admin {
        margin-left: 115px;
        display: flex;
        gap: 15px;
      }
    }
    .dropdown-sort .dropdown-menu.show {
      transform: translate3d(-140px, 26px, 0px) !important;
    }
    .sidebar-list {
      height: calc(100vh - 230px);
    }
  }
  .sidebar-list {
    height: calc(100vh - 230px);
    padding-right: 25px;
  }
  .toggle {
    cursor: pointer;
    .svg-icon {
      transition: 0.4s linear;
    }
  }
}
.sidebar-expand {
  display: flex;
  gap: 30px;
  align-items: center;
  background: $n-7;
  padding: 16px;
  margin-bottom: 20px;
  margin-right: 25px;
  &-item {
    font-weight: 100;

    &.title {
      width: 200px;
      cursor: pointer;
    }
    &.date {
      width: 100px;
      cursor: pointer;
    }
    &.impression {
      width: 100px;
      cursor: pointer;
    }
    &.spending {
      width: 100px;
      cursor: pointer;
    }
    &.bidrate {
      width: 100px;
      cursor: pointer;
    }
    &.floorprice {
      width: 100px;
      cursor: pointer;
    }
    &.earnings {
      width: 100px;
      cursor: pointer;
    }
    &.status {
      width: 100px;
      cursor: pointer;
    }
    &.fillrate {
      width: 100px;
      cursor: pointer;
    }
    &.action {
      width: 30px;
    }
  }
}
.expanded-row {
  display: flex;
  gap: 30px;
  align-items: center;
  &-img {
    background-color: $p-2;
    width: 28px;
    height: 28px;
    font-weight: 200;
    object-fit: cover;
    font-size: 10px;
    padding-top: 2px;
    color: $n-9;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
  }
  &-item {
    &.title {
      font-weight: 100;
      width: 200px;
      display: flex;
      align-items: center;
      gap: 10px;
    }
    &.date {
      width: 100px;
    }
    &.impression {
      width: 100px;
    }
    &.spending {
      width: 100px;
    }
    &.bidrate {
      width: 100px;
    }
    &.floorprice {
      width: 100px;
    }
    &.earnings {
      width: 100px;
    }
    &.status {
      width: 100px;
    }
    &.fillrate {
      width: 100px;
    }
    &.action {
      width: 30px;
    }
  }
  &-title {
    width: 100px;
  }
}

/********** New Sidebar CSS END **********/

.publisher-spo {
  &.open-sidebar {
    .sidebar-row-secound,
    .ssp-deals-sidebar-tabs {
      display: none;
    }
  }
}

.sidebar-col {
  .disabled {
    opacity: 0.5;
    pointer-events: none;
  }
}
