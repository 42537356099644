.barside {
  top: 66px;
  min-width: 56px;
  position: fixed;
  background: $n-9;
  padding: 26px 14px 14px;
  height: calc(100vh - 66px);
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.04);
}

.barside-icons {
  display: flex;
  row-gap: 30px;
  flex-direction: column;
  justify-content: flex-start;

  &-count {
    display: flex;
    border-radius: 100%;
    position: absolute;
    font-size: 10px;
    font-weight: 300;
    color: $n-9;
    background-color: $s-e-2;
  }
}

.barside-panel {
  right: 0;
  top: 66px;
  display: flex;
  min-width: 300px;
  position: fixed;
  padding: 24px 8px;
  flex-direction: column;
  height: calc(100vh - 66px);
  background-color: $n-9;
  // border: 1px solid $n-8;
  overflow-y: auto;
  z-index: 3;
  justify-content: flex-start;

  &-small {
    color: $p-2;
    font-size: 12px;
    cursor: pointer;
  }

  &-disable {
    color: $n-4;
    font-size: 12px;
    pointer-events: none;
    cursor: default;
  }

  &-card {
    margin: 8px;
    padding: 8px;
    border-radius: 4px;
    cursor: pointer;
    border: 1px solid $n-8;
    max-width: 268px;

    &-time {
      color: $n-4;
      font-weight: 500;
    }

    &-status {
      height: 10px;
      width: 10px;
      background-color: $s-e-2;
      border-radius: 20px;
      position: absolute;
      top: 0px;
      left: -10px;
    }
  }
}
