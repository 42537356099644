.createfrom {
    &-title {
        font-weight: lighter;
        font-size: 24px;
        margin-bottom: 20px;
    }

    &-lable {
        font-weight: lighter;
        font-size: 16px;

        h3 {
            width: 185px;
            font-size: 16px;
        }

        .svg-icon {
            position: absolute;
            right: 5px;
        }
    }
}