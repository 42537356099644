.search-input-wrap {
  max-width: 225px;
  max-width: 225px;

  .text-input-mini {
    max-width: 100%;
    width: 100%;
    padding-left: 0px;
    padding-right: 0px;
    height: 38px;
  }
}

input[type='search']::-webkit-search-cancel-button {
  display: none;
}
