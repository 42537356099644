.customers-invite-modal .form-group .radio-mini {
  padding: 3px 0px;
}

.modal {
  &-content {
    max-width: 100%;
  }

  &-lg {
    max-width: 800px;
  }

  &-sm {
    max-width: 1200px;
  }
}

.vasttag.modal {
  .light-shadow {
    margin-top: 0px !important;
  }
}

.tabs-modal {
  .tab-pane {
    .vast-title {
      display: none;
    }
  }
}

.macros-modal-body-scroll {
  max-height: 400px;
  overflow-y: auto;

  .macros-modal-body {
    padding-right: 12px;
  }

  .react-select__menu {
    form {
      padding: 0px 15px;
    }
  }

  .react-select__menu-list {
    max-height: 200px !important;
  }
}
.hb-login-modal {
  &::after {
    content: '';
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;
    background: $n-1;
    opacity: 0.5;
    z-index: -1;
  }
}

.creative-modal {
  z-index: 1050;

  .modal-dialog {
    min-width: 95%;
  }
}
