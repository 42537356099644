.rtb-form .form-group .radio {
    padding: 0px;
}

.tabs-modal .tab-content {
    display: block;
    margin: 0px;
}

.device-types-lists {
    span {
        position: relative;
        margin-right: 10px;
        display: inline-block;

        &::after {
            position: absolute;
            content: ",";
            right: -4px;
            top: -3px;
            width: auto;
            height: auto;
        }

        &:last-child {
            &::after {
                display: none;
            }
        }
    }
}

.title-truncate {
    // width: 50px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}