.settings-sidebar {
  padding: 25px;
  height: calc(100vh - 66px);
  background-color: $n-9;
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.04);

  @media (min-width: 320px) {
    min-width: 300px;
  }

  @media (min-width: 768) {
    min-width: 260px;
  }

  @media (min-width: 992) {
    min-width: 300px;
  }
}

.settings-menu {
  display: flex;
  row-gap: 24px;
  margin-top: 48px;
  flex-direction: column;
}

.settings-menu-list {
  display: flex;
  cursor: pointer;
  column-gap: 16px;
  padding: 8px 24px 8px 8px;
  border-radius: 4px;
  border: 1px solid $n-9;
  // max-width: fit-content;

  &:hover {
    background-color: $p-7;
    border-color: $p-2;
    font-weight: 200;

    .svg-icon {
      filter: brightness(0%);
    }
  }
}

.settings-menu-list-selected {
  font-weight: 200;
  border-radius: 4px;
  border: 1px solid $p-2;
  background-color: $p-7;

  .svg-icon {
    filter: brightness(0%);
  }
}

.settings-container {
  width: 100%;
  height: calc(100vh - 66px);
  overflow-x: hidden;
  overflow-y: auto;
  padding: 40px;
  z-index: 0;
  position: relative;

  .tab-content {
    height: calc(100vh - 120px);
  }

  .table__responsive {
    overflow-x: initial;
  }

  .card {
    cursor: default;
  }
}

.upload {
  &-file {
    position: absolute;
    width: 50px;
    height: 50px;
    right: -5px;
    bottom: 25px;
    background-color: $p-2;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    overflow: hidden;
    &:hover {
      background-color: $p-3;
    }

    input {
      width: 50px;
      height: 50px;
      opacity: -1;
      z-index: 9;
      cursor: pointer;
    }

    &::after {
      content: '';
      background: url(../../icons/choose-image.png) no-repeat;
      width: 30px;
      height: 30px;
      position: absolute;
      // left: 0;
      // right: 0;
      margin: 0 auto;
      z-index: 1;
      background-size: 100% 100%;
    }
  }

  &-loading {
    position: absolute;
    width: 100%;
    height: 35px;
    left: 0;
    bottom: -35px;
    background-color: $p-2;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    &:hover {
      background-color: $p-3;
    }

    &::after {
      content: 'Uploading...';
      width: 100%;
      height: 25px;
      position: absolute;
      left: 0;
      right: 0;
      margin: 0 auto;
      z-index: 1;
      background-size: 100% 100%;
      color: $n-9;
      text-align: center;
      line-height: 24px;
    }

    span {
      z-index: 9;
      width: 100%;
      height: 50px;
      cursor: pointer;
    }
  }

  &-button {
    position: absolute;
    width: 100%;
    height: 35px;
    left: 0;
    bottom: -35px;
    background-color: $p-2;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    &:hover {
      background-color: $p-3;
    }

    &::after {
      content: 'Upload Image';
      //background: url(../../icons/upload.png) no-repeat;
      width: 100%;
      height: 25px;
      position: absolute;
      left: 0;
      right: 0;
      margin: 0 auto;
      z-index: 1;
      background-size: 100% 100%;
      color: $n-9;
      text-align: center;
      line-height: 24px;
    }

    span {
      z-index: 9;
      width: 100%;
      height: 50px;
      cursor: pointer;
    }
  }
}
