.list {
    width: 100%;
    border: none;
    margin: 8px 0;
    padding: 8px;
    cursor: pointer;
    border-radius: 4px;
    background-color: $n-9;
}

.list:hover:not(.list-selected) {
    background-color: $n-8;
}

.list-selected {
    border: 1px solid $p-2;
    background-color: $p-7;
}

.list-row {
    display: flex;
    justify-content: space-between;
}

.list-col {
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-width: fit-content;
    width: 100px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}

.list-col:hover {
    overflow: visible;
}

// .endpoint-list .sidebar-list .card.card-selected {
//     border: none;
//     background-color: transparent;
// }