.active-link,
.active-link:hover,
.active-link:focus {
  color: $n-1;
  font-weight: 200;
}

.text-info-1 {
  color: $n-2;
}

// Removed black outline while using tab key
a,
button {
  &:focus-visible {
    outline: transparent;
  }
}
