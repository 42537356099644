.react-datepicker__header {
  background-color: $n-9;
}

.react-datepicker__navigation {
  top: 8px;
}

.react-datepicker-popper[data-placement^='bottom'] .react-datepicker__triangle::after {
  border-bottom-color: $n-9;
}

.react-datepicker__day--in-range {
  background-color: $p-2;
}

.react-datepicker__input-container > div {
  justify-content: flex-end;
}

.calendar__wrap {
  position: relative;

  &__range {
    display: flex;
    justify-content: flex-end;
    color: $n-4;
    font-size: 14px;
    font-weight: 200;
    cursor: pointer;
    max-width: 230px;
    margin-left: auto;
    align-items: center;

    &:hover {
      .svg-icon,
      div {
        filter: brightness(0%);
      }
    }
  }

  &__modal {
    position: absolute;
    top: 0px;
    right: 0px;
    background: $n-9;
    // box-shadow: rgb(0 0 0 / 4%) 0px 0px 8px;
    // padding: 12px;
    z-index: 2;

    &__close {
      display: none !important;
      position: absolute;
      top: -20px;
      right: -20px;
      background-color: $p-2;
      width: 15px;
      height: 15px;
      padding: 15px;
      border-radius: 20px;
      display: flex;
      justify-content: center;
      align-items: center;
      color: $n-9;
      cursor: pointer;
    }

    .rdrDateDisplayItemActive {
      border-color: $p-2;

      input {
        color: $p-2;
      }
    }

    .rdrDateDisplayItem {
      &:hover {
        border-color: $p-2;

        input {
          color: $p-2;
        }
      }
    }

    .rdrDateDisplayWrapper,
    .rdrNextPrevButton {
      background-color: $n-8;
    }

    .rdrStaticRangeLabel {
      padding: 16px 20px;
      font-size: 13px;
    }

    .rdrStaticRanges {
      .rdrStaticRange {
        &.rdrStaticRangeSelected {
          background: $n-8;

          .rdrStaticRangeLabel {
            color: $p-2;
          }
        }

        &:hover {
          .rdrStaticRangeLabel {
            background: $n-8;
          }
        }
      }
    }

    .rdrMonthAndYearPickers {
      select {
        &:hover {
          background-color: $n-8;
        }
      }
    }

    .rdrMonths {
      .rdrDays {
      }
    }

    .rdrDayToday {
      .rdrDayNumber {
        span {
          &::after {
            background: $p-2;
          }
        }
      }
    }
  }

  &__overlay {
    &::after {
      background: $n-1;
      position: fixed;
      top: 0;
      right: 0;
      width: 100%;
      height: 100%;
      content: '';
      opacity: 0.1;
      z-index: -1;
    }
  }
}

.endpoint-list {
  .calendar__wrap {
    margin-top: 0;
    position: absolute;
    right: 10px;
    top: 40px;
  }

  .calendar__wrap__modal {
    top: 0px;
    right: 30px;
  }
}

.react-date-picker {
  .react-datepicker__header {
    background-color: $p-7;
  }

  .react-datepicker__day {
    &--keyboard-selected {
      background-color: $p-2;
      color: $n-9;
    }

    &--selected {
      background-color: $p-2;
    }

    &:hover {
      background-color: $p-2;
      color: $n-9;
    }
  }
}

.react-datepicker__time-list-item {
  &--selected {
    background-color: $p-2 !important;
  }

  &:hover {
    background-color: $p-2 !important;
    color: $n-9;
  }
}

.react-datepicker__navigation-icon {
  filter: invert(49%) sepia(0%) saturate(928%) hue-rotate(200deg) brightness(95%) contrast(96%);

  &:hover {
    filter: brightness(0%);
    //filter: invert(84%) sepia(41%) saturate(4888%) hue-rotate(238deg) brightness(70%) contrast(90%);
    cursor: pointer;
  }
}

.react-datepicker-wrapper {
  width: 100%;
}
