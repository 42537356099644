.mobile {
  height: 670px;
}
.web {
  height: 495px;
}
.device-view {
  &-tabs {
    border-bottom: 1px solid $n-5;
    margin-top: 18px;
    &.details {
      margin-top: 7px;
    }
    button {
      padding: 6px;
      border: 1px solid $n-5;
      //border-bottom: none;
      background-color: transparent;
      border-radius: 4px 4px 0px 0px;
      width: 50px;
      height: 38px;
      line-height: 19px;
      &.active {
        border-color: $p-2;
        background-color: $p-7;
      }
    }
  }
}
.device-view-body {
  min-height: 650px;
  height: 650px;
  overflow-y: auto;
}
.DevicePreview-module_section {
  flex: 1 1 auto;
  max-height: calc(100% - 81rem / 16);
  height: 100%;
  width: 100%;
  font-size: 0;
  line-height: 0;
  text-align: center;
  padding: 20px;
}
.DevicePreview-module_component {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  height: 100%;
  overflow: hidden;
}
.DevicePreview-module_image {
  display: inline-block;
  vertical-align: middle;
  max-width: 100%;
  max-height: 100%;
}
.index-module_header {
  height: 40px;
  //border-bottom: 0.01em solid #787878;
}
.index-module_container {
  display: flex;
  align-items: center;
  height: 100%;
  margin: 0 auto;
  width: 79.23077%;
}
.index-module_logo {
  margin-right: 23px;
  min-width: 15px;
  height: 15px;
  border-radius: 50%;
  background: $p-2;
}
.index-module_headerItem {
  margin-right: 7px;
  width: 25px;
  height: 7px;
  border-radius: 20px;
  background: $n-5;
}
.index-module_search {
  margin-left: 5px;
  margin-right: 2px;
  width: 53px;
  height: 7px;
  border: 1px solid $n-5;
}
.index-module_profileImage {
  margin-left: auto;
  margin-right: 1.2px;
  min-width: 7px;
  height: 7px;
  border-radius: 50%;
  background: $n-5;
}
.DevicePreview-module_site {
  width: 100%;
  height: 0;
  overflow: hidden;
  position: static;
  transform: none;
  left: 0;
  top: 0;
  padding-bottom: 83.0703%;
}
.DevicePreview-module_wrapper {
  position: absolute;
  overflow: hidden;
  visibility: visible;
  top: 25px;
  left: 0;
  right: 0;
  height: 60%;
}
.DevicePreview-module_wrapperScroller {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  width: 100%;
  overflow: auto;
  z-index: 0;
}
.index-module_profileName {
  width: 36px;
  height: 7px;
  background: $p-2;
}
.DevicePreview-module_container {
  margin: 0 auto;
  width: 79.23077%;
}
.DevicePreview-module_main {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding-top: 13px;
}
.index-module_leftSidebar {
  padding-right: 17px;
}
.index-module_leftSidebarItem {
  width: 47px;
  height: 7px;
  border-radius: 20px;
  background: $n-5;
  margin-bottom: 7px;
}
.index-module_content {
  flex: 1 1 auto;
  max-width: 100%;
}
.index-module_mainHeader {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 10px;
  padding: 10px;
  background: $n-5;
}
.index-module_mainHeaderItem {
  width: 100%;
  background: $n-9;
  border-radius: 20px;
}
.index-module_mainHeaderItem:first-child {
  max-width: 60%;
  height: 8px;
  margin-bottom: 10px;
}
.index-module_mainHeaderItem:nth-child(2) {
  max-width: 80%;
  height: 8px;
  margin-bottom: 10px;
}
.index-module_mainHeaderItem:nth-child(3) {
  max-width: 60%;
  height: 8px;
}
.index-module_block {
  padding: 10px;
  background: $n-5;
  margin-bottom: 10px;
}
.index-module_previewWrapper {
  position: relative;
  width: 90%;
  font-size: 0;
  margin-bottom: 20px;
  iframe {
    width: 100%;
    max-width: 370px;
    height: auto;
    aspect-ratio: 1/2;
  }
}
.index-module_row {
  display: flex;
  align-items: center;
  margin-bottom: 8px;
}
.index-module_item {
  height: 8px;
  border-radius: 20px;
  background: $n-9;
}
.index-module_image {
  width: 100%;
  background: $n-9;
}
.index-module_rightSidebar {
  margin-left: 15px;
  padding: 10px;
  min-width: 80px;
  background: $n-5;
}
.index-module_rightSidebar_1 {
  margin-left: 15px;
  margin-top: 15px;
}
.index-module_rightSidebar_2 {
  margin-left: 15px;
  margin-top: 15px;
}
.index-module_rightSidebarItem {
  height: 7px;
  border-radius: 20px;
  background: $n-9;
  margin-bottom: 10px;
}
.device-view-wrap {
  .mobile {
    padding: 20px 100px;
    .DevicePreview-module_wrapper {
      height: 87%;
      top: 40px;
    }
    .index-module_container {
      .index-module_headerItem {
        &:nth-of-type(4),
        &:nth-of-type(5),
        &:nth-of-type(6) {
          display: none;
        }
      }
      .index-module_search {
        display: none;
      }
    }
    .index-module_leftSidebar,
    .index-module_rightSidebar,
    .index-module_rightSidebar_1,
    .index-module_rightSidebar_2,
    .index-module_block_endroll {
      display: none;
    }
    .index-module_previewWrapper {
      width: 100%;
    }
  }
  .web {
    padding: 20px 0px;
  }
  .index-module_rightSidebar_2 {
    margin-top: 120px;
    margin-right: -20px;
  }
}

// For new component CREATIVE
.newpreview {
  .DevicePreview-module_wrapper {
    top: 28px;
  }
}
