.tabs-tags-sources {
  left: 0;
  right: 0;

  .tags-tabs-button {
    .nav-item.active {
      border: none;
      color: $p-2;
    }

    .nav-item {
      border: none;
      color: $n-1;
      font-weight: 200;
      font-size: 16px;
      min-width: fit-content;

      &:hover {
        border: none;
      }
    }
  }

  .nav-tabs {
    position: absolute;
    //top: 11px;
    top: -52px;

    margin-left: 350px;
    border-style: none;
  }

  &.position-absolute {
    .tour-ssp-tabs.nav-tabs {
      z-index: 1;
    }
  }

  .tab-content {
    display: block;
    margin-top: 0px;
  }

  .endpoint-list,
  .performance-setting {
    .nav-tabs {
      margin-left: 0px;
    }
    &.active {
      .nav-tabs {
        visibility: hidden;
      }
    }
    &.deactive {
      .nav-tabs {
        visibility: visible;
      }
    }
  }
}

.tabs-tags-sources {
  .tabs-key {
    .nav-item.active {
      border: none;
    }

    .nav-item {
      border: none;

      &:hover {
        border: none;
      }
    }
  }
}

.tabs-tags-sources {
  .reports {
    .tab-content {
      display: none;
      margin-top: 10px;
      min-height: 100px;
    }

    .tab-content-active {
      display: block;
    }
  }
}

.tab-container {
  width: 100%;
}

.tabs {
  width: 100%;
  border-bottom: 1px solid $n-6;
}

.tab-button {
  border: none;
  color: $n-4;
  background: none;
  margin: 32px 8px 0px;

  &:hover {
    color: $n-1;
    font-weight: 200;
    border-bottom: 2px solid $p-2;
  }
}

.tab-button-active {
  font-weight: 200;
  color: $n-1;
  border-bottom: 2px solid $p-2;
  outline: none;
}

.tab-content {
  display: none;
  margin-top: 10px;
  min-height: 100px;
}

.tab-content-active {
  display: block;
}

.tabs-modal .nav-tabs {
  border-bottom: 1px solid $n-6;
  padding: 10px 15px 0px;
}

.tabs-modal .nav-tabs .nav-item {
  border-color: transparent;
  background-color: transparent;
}

.tabs-modal .nav-tabs .nav-item.active {
  border-bottom: 2px solid $p-2;
  font-weight: 200;
  color: $p-1;
}

.tabs-modal .nav-tabs .nav-item {
  color: $n-4;
  margin: 0px 8px;
  padding-bottom: 10px;
}

.pill-tabs .tab-content {
  display: block;
  margin: 0px;
}

.pill-tabs .nav-tabs {
  display: inline-flex;
  position: absolute;
  top: 0px;
  left: 50%;
  transform: translateX(-50%);
  border: none;
  margin-top: 33px;
  //z-index: 1;
}

.pill-tabs .nav-tabs .nav-item {
  color: $n-2;
  padding: 7px 12px;
  background-color: $n-9;
  border: 1px solid $n-5;
  font-size: 14px;
  border-radius: 0;

  &:hover {
    color: $n-1;
    background-color: $p-6;
    border: 1px solid $p-2;
  }
}

.pill-tabs .nav-tabs .nav-item {
  // width: 100px;
  text-align: center;
  // margin: 0px -1px;
}

.pill-tabs .nav-tabs .nav-item:nth-of-type(1) {
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
}

.pill-tabs .nav-tabs .nav-item:nth-of-type(2) {
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
}

.pill-tabs .nav-tabs .nav-item.active {
  color: $n-1;
  background-color: $p-7;
  border-color: $p-2;
}

.pill-tabs .nav-tabs .nav-item:not(:last-child) {
  border-right: none;
  position: relative;

  &::after {
    width: 1px;
    height: 100%;
    background: $p-2;
    position: absolute;
    right: 0;
    top: 0;
    content: '';
  }

  &:hover {
    border-right: none;
  }
}

.pill-tabs .nav-tabs .nav-item:not(:first-child) {
  border-left: none;

  &:hover {
    border-left: none;
  }
}

// .tab-button {
//   border: none;
//   color: $n-4;
//   background: none;
//   margin: 32px 8px 0px;

//   &:hover {
//     color: $n-1;
//     font-weight: 200;
//     border-bottom: 2px solid $p-2;
//   }
// }

// .tab-button-active {
//   font-weight: 200;
//   color: $n-1;
//   border-bottom: 2px solid $p-2;
// }

.tab-content {
  display: none;
  margin-top: 10px;
  min-height: 100px;
}

.tab-content-active {
  display: block;
}

.tabs-modal .nav-tabs {
  border-bottom: 1px solid $n-6;
  padding: 10px 15px 0px;
}

.tabs-modal .nav-tabs .nav-item {
  border-color: transparent;
  background-color: transparent;
}

.tabs-modal .nav-tabs .nav-item.active {
  border-bottom: 2px solid $p-2;
  font-weight: 200;
  color: $p-1;
}

.tabs-modal .nav-tabs .nav-item {
  color: $n-4;
  margin: 0px 8px;
  padding-bottom: 10px;
}

.pill-tabs .tab-content {
  display: block;
  margin: 0px;
}

.pill-tabs .nav-tabs .nav-item {
  color: $n-2;
  padding: 7px 12px;
  background-color: $n-9;
  border: 1px solid $n-5;
  font-size: 14px;
  border-radius: 0;

  &:hover {
    color: $n-1;
    background-color: $p-7;
    border: 1px solid $p-2;
  }
}

.tabs-modal {
  // .modal-content {
  //     max-height: 400px;
  // }

  .tab-content {
    display: block;
    margin: 0;
  }
}

.setting {
  .setting-left {
    padding: 25px;
    height: calc(100vh - 66px);
    background-color: $n-9;
    box-shadow: 0 0 8px rgb(0 0 0 / 4%);
    max-width: 300px;
    position: fixed;
    overflow-y: auto;

    .nav-pills {
      margin-top: 48px;
    }

    .nav-item {
      margin-bottom: 25px;

      .nav-link {
        padding: 9px 24px 9px 10px;
        color: $n-1;

        &.active {
          background-color: $p-7;
          color: $n-1;
          font-weight: 200;
          border: 1px solid $p-2;
        }

        svg {
          margin-right: 14px;
        }
      }
    }
  }
  .setting-right {
    margin-left: 300px;
    width: calc(100% - 300px);
    padding-right: 60px;
    padding-left: 0px;
  }

  .tab-content {
    display: block;
    margin: 0;
  }
}

.tabs-modal {
  &.hide-tabs-header {
    .nav-tabs {
      display: none;
    }
  }

  .genuin-tab {
    position: absolute;
    right: 0px;
    top: 7px;

    .tab-image {
      width: 23px;
      border-radius: 4px;
      // filter: saturate(0%) brightness(320%);
    }

    &.active {
      color: #0645ff !important;
      border-bottom: 2px solid #0645ff !important;

      // .tab-image {
      //   filter: saturate(95%) brightness(100%);
      // }
    }
  }
}

.tags-sources-pill {
  .ssp-demand-report {
    .tab-content {
      display: none;
    }
    .tab-content.tab-content-active {
      display: block;
    }
  }
}

.main__container.ssp-deals {
  padding-top: 30px;
}
.ssp-deals-sidebar {
  h3 {
    // margin: 15px 0px;
    margin: 7px 0px 20px;
  }
}

.admin-deals-sidebar {
  h3 {
    margin: 15px 0px;
  }
}

.tabs-length-4 .tab-button:nth-child(n + 5) {
  display: none;
}
.tabs-length-4 .tab-content:nth-child(n + 5) {
  display: none !important;
}

.infy-table {
  overflow-y: auto;
  height: calc(100vh - 200px);
  &.fixed {
    height: calc(100vh - 200px);
  }
  &.auto {
    height: auto;
  }
}

/***** DSP Header Tabs Start *****/

.tabs-dsp-header {
  left: 0;
  right: 0;

  .tags-tabs-button {
    .nav-item.active {
      border: none;
      color: $p-2;
    }

    .nav-item {
      border: none;
      color: $n-1;
      font-weight: 200;
      font-size: 16px;
      min-width: fit-content;

      &:hover {
        border: none;
      }
    }
  }

  .nav-tabs {
    position: absolute;
    //top: 11px;
    top: -52px;

    margin-left: 350px;
    border-style: none;
  }

  &.position-absolute {
    .tour-ssp-tabs.nav-tabs {
      z-index: 1;
    }
  }

  .tab-content {
    display: block;
    margin-top: 0px;
  }

  .endpoint-list,
  .performance-setting {
    .nav-tabs {
      margin-left: 0px;
    }
    &.active {
      .nav-tabs {
        visibility: hidden;
      }
    }
    &.deactive {
      .nav-tabs {
        visibility: visible;
      }
    }
  }
}

.tabs-dsp-header {
  .tabs-key {
    .nav-item.active {
      border: none;
    }

    .nav-item {
      border: none;

      &:hover {
        border: none;
      }
    }
  }
}

.tabs-dsp-header {
  .reports {
    .tab-content {
      display: none;
      margin-top: 10px;
      min-height: 100px;
    }

    .tab-content-active {
      display: block;
    }
  }
}

.tabs-dsp-header {
  .pill-tabs {
    .nav-tabs {
      top: 0;
    }
  }
}

/***** DSP Header Tabs End *****/

/***** SSP DEALS Sidebar Tabs Start *****/
.ssp-deals-sidebar-tabs {
  position: absolute;
  top: 65px;
  left: 25px;

  a {
    color: $n-2;
    padding: 7px 10px;
    background-color: $n-9;
    border: 1px solid $n-5;
    display: inline-block;
    &.active {
      color: $n-1;
      background-color: $p-7;
      border: 1px solid $p-2;
    }
    &:nth-child(1) {
      border-top-left-radius: 4px;
      border-bottom-left-radius: 4px;
    }
    &:nth-child(2) {
      border-top-right-radius: 4px;
      border-bottom-right-radius: 4px;
    }
  }

  .pill-tabs {
    nav {
      a {
        min-width: fit-content;
      }
    }
  }

  .nav-tabs {
    position: absolute;
    //top: 11px;
    top: -32px;
    margin-left: 230px;
    border-style: none;
  }

  .nav {
    flex-wrap: nowrap;
  }
}

.header + .main-container {
  .publisher-spo {
    .ssp-deals-sidebar-tabs {
      top: 87px;
    }
  }
}
.view-top-bar.header + .main-container {
  .publisher-spo {
    .ssp-deals-sidebar-tabs {
      top: 135px;
    }
  }
}
.publisher-spo {
  // .sidebar-row-first {
  //   height: 42px;
  // }
  .ssp-deals-sidebar-tabs {
    z-index: 2;
    left: 25px;
    top: 24px;
    position: fixed;
  }
}

.sidebar-tab-wrap {
  h3 {
    margin: 7px 0px 20px;
  }
  .sidebar-tabs-wrap {
    position: absolute;
    top: 65px;
    left: 25px;

    .pill-tabs {
      nav {
        a {
          min-width: fit-content;
        }
      }
    }

    .nav-tabs {
      position: absolute;
      //top: 11px;
      top: -32px;
      margin-left: 230px;
      border-style: none;
    }

    .nav {
      flex-wrap: nowrap;
    }
  }
  .newsidebar {
    .sidebar-row-secound {
      justify-content: flex-end;
    }
  }
}
/***** SSP DEALS Sidebar Tabs End *****/

.tabs-container {
  .nav {
    &-link {
      color: $n-2;
      padding: 7px 12px;
      background-color: $n-9;
      border: 1px solid $n-5;
      font-size: 14px;
      &.active {
        color: $n-1;
        background-color: $p-7;
        border: 1px solid $p-2;
      }
    }
    &-item {
      &:nth-of-type(1) {
        .nav-link {
          border-radius: 4px 0px 0px 4px;
        }
      }
      &:nth-of-type(2) {
        .nav-link {
          border-radius: 0px 4px 4px 0px;
        }
      }
    }
  }
}

.customer-details-table {
  margin-top: 35px;
  z-index: 0;
  position: relative;
  .search-wrap {
    margin-top: 0px;
  }
  .tab-content {
    display: block;
  }
  .nav {
    &-link {
      border: none;
      margin-bottom: 0;
      padding: 0px 5px;
      margin: 0px 8px 0;
      color: $n-4;

      &:hover {
        color: $n-1;
        font-weight: 200;
        border-bottom: 2px solid $p-2;
      }

      &.active {
        color: $n-1;
        font-weight: 200;
        border-bottom: 2px solid $p-2;
      }
    }
  }
}

.custome-tabs {
  .tab-content {
    display: block;
  }
  .nav {
    display: flex;
    flex-wrap: wrap;
    padding-left: 0;
    margin-bottom: 0;
    list-style: none;
    &-link {
      color: $n-2;
      padding: 7px 12px;
      background-color: $n-9;
      border: 1px solid $n-5;
      font-size: 14px;

      &.active {
        color: $n-1;
        background-color: $p-7;
        border: 1px solid $p-2;
      }
    }
    &-item {
      &:nth-of-type(1) {
        .nav-link {
          border-radius: 4px 0px 0px 4px;
        }
      }
      &:nth-of-type(2) {
        .nav-link {
          border-radius: 0px 4px 4px 0px;
        }
      }
    }
  }
  &-body {
    .tab-content {
      border-radius: 4px;
    }
    #gen_ext {
      margin: 0 auto;
    }
  }
}

.modal-details-tabs,
.ads-view-tabs {
  .nav {
    border-bottom: none;
    &-item {
      margin: 0px !important;
      background-color: $n-9 !important;
      border: 1px solid $n-5 !important;
      padding-bottom: 8px !important;
      &.active {
        color: $n-2 !important;
        border: 1px solid $p-2 !important;
        font-weight: 400 !important;
        background-color: $p-7 !important;
      }
    }
  }
  .tab-content {
    margin-top: 0px;
  }
  .createtag-vasturl {
    border-radius: 0px 4px 4px;
  }
}

.ads-view-tabs {
  margin-top: 10px;
  .nav {
    border-bottom: none;
  }
  .tab-content {
    margin-top: 0px;
    border: 1px solid $n-5;
    border-radius: 0px 4px 4px;
    .mobile-view {
      padding: 50px;
      background: url('../../images/iphone14.png') no-repeat;
      background-size: 333px 628px;
      background-position: center;
      #gen_ext {
        margin: 0 auto;
        border-radius: 33px;
      }
    }
    .web-view {
      margin: 50px;
      background: url('../../images/dsk.png') no-repeat;
      background-size: contain;
      background-position: center;
      #gen_ext {
        margin: 0 auto;
        border-radius: 33px;
      }
    }
  }
}

.demand-report {
  .tab-content.tab-content-active {
    display: block;
  }
  .tab-content {
    display: none;
  }
}

.extension-tabs {
  .tab-content {
    display: block;
  }
}

.finance-container {
  &.admin,
  &.spo,
  &.dsp {
    .nav-tabs {
      margin-top: -30px !important;
      margin-bottom: 40px !important;
    }
  }
  &.admin,
  &.dsp {
    .nav-tabs {
      .nav-link {
        &:nth-child(1) {
          border-top-left-radius: 4px;
          border-bottom-left-radius: 4px;
        }
        &:nth-child(2) {
          border-top-right-radius: 4px;
          border-bottom-right-radius: 4px;
        }
      }
    }
  }
  &.ssp,
  &.spo {
    .nav-tabs {
      .nav-link {
        &:first-child {
          border-top-left-radius: 4px;
          border-bottom-left-radius: 4px;
        }
        &:last-child {
          border-top-right-radius: 4px;
          border-bottom-right-radius: 4px;
        }
      }
    }
  }
  .nav-tabs {
    justify-content: center;
    border-bottom: none;
    margin-top: -50px !important;
    margin-bottom: 30px !important;
    .nav-link {
      border: 1px solid $n-5; // Initial border
      border-radius: 0; // Optional: Adjust border radius as needed
      margin: 0; // Optional: Remove default margin for nav-links
      color: $n-2;

      &:not(.active) {
        &:hover {
          border-color: $n-5; // Change border color on hover
        }
      }

      &.active {
        color: $n-1;
        background-color: $p-7;
        border: 1px solid $p-2;
        outline: none; // Remove outline on focus
      }
    }
  }
}

.on-bording {
  &-tabs {
    &.nav-tabs {
      justify-content: center;
      border-bottom: none;
      margin-bottom: 35px;
      .nav-link {
        &:nth-child(1) {
          border-top-left-radius: 4px;
          border-bottom-left-radius: 4px;
        }
        &:nth-child(3) {
          border-top-right-radius: 4px;
          border-bottom-right-radius: 4px;
        }
      }
      .nav-link {
        border: 1px solid $n-5; // Initial border
        border-radius: 0; // Optional: Adjust border radius as needed
        margin: 0; // Optional: Remove default margin for nav-links
        color: $n-2;

        &:not(.active) {
          &:hover {
            border-color: $n-5; // Change border color on hover
          }
        }

        &.active {
          color: $n-1;
          background-color: $p-7;
          border: 1px solid $p-2;
          outline: none; // Remove outline on focus
        }
      }
    }
  }
  .tab-content {
    display: block;
  }
}
