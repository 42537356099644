.react-joyride + .view-top-bar,
.react-joyride + .header {
  pointer-events: none;
}
.react-joyride + .view-top-bar + .main-container,
.react-joyride + .header + .main-container {
  pointer-events: none;
}
.tour-overlay {
  &::after {
    content: '';
    //background-color: rgba(0, 0, 0, 0.1);
    background-color: transparent;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;
    pointer-events: none;
    z-index: 1;
    cursor: none;
  }
}

body.tour-empty{
  overflow: hidden;
}