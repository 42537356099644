.button,
.btn {
  outline: 0;
  border: none;
  height: 38px;
  font-size: 14px;
  font-weight: 200;
  box-shadow: none;
  padding: 6px 16px;
  border-radius: 4px;
  min-width: 80px; // common for all button
  // width: fit-content;
  letter-spacing: 0.2px;

  .svg-icon {
    //filter: brightness(0%);
    //filter: invert(84%) sepia(41%) saturate(4888%) hue-rotate(238deg) brightness(50%) contrast(90%);
    // filter: invert(29%) sepia(79%) saturate(1404%) hue-rotate(199deg) brightness(97%) contrast(101%);
    fill: $p-1;
    filter: inherit !important;
    margin-right: 5px;
    font-size: 18px;
  }
}

.button-full {
  width: 100%;
}

.button-primary,
.btn-primary {
  color: $n-9;
  background-color: $p-2;

  &:disabled {
    background-color: $p-3;
  }
}

.button-loading {
  color: $n-9 !important;
  background-color: $p-2 !important;
  opacity: 0.7 !important;
}

.button-primary:hover {
  background-color: $p-3;
}

.button-primary:active {
  background-color: $p-4;
}

.button-secondary {
  color: $p-2;
  background-color: transparent;
  border: 1px solid $p-2;
}

.button-secondary:hover {
  background-color: $p-7;
}

.button-secondary:active {
  color: $p-1;
  border: 1px solid $p-1;
}

.button-success {
  color: $s-s-1;
  background-color: transparent;
  border: 1px solid $s-s-1;
}

.button-error {
  color: $s-e-1;
  background-color: transparent;
  border: 1px solid $s-e-1;

  &:hover {
    background-color: $s-e-3;
  }
}

.button-link {
  color: $p-2;
  border-radius: 0px;
  background-color: transparent;
}

.button-link:hover {
  border-bottom: 2px solid $p-2;
}

.button-disabled {
  color: $n-3;
  border: 1px solid $n-6;
  background-color: transparent !important;
  pointer-events: none;
}

.button-disabled-secondary {
  color: $n-3;
  border: 1px solid $n-5;
  background-color: transparent;
}

.button-gray {
  color: $n-2;
  background-color: $n-6;

  &:hover {
    background-color: $n-5;
  }
}

.button-overview {
  color: $n-2;
  background-color: transparent;
  border: 1px solid $n-2;

  &:hover {
    background-color: $p-7;
    color: $p-2;
    border-color: $p-2;
  }
}

.button-google {
  background-color: #336ced;
  color: $n-9;
  border-radius: 0px;
  cursor: pointer;
  width: fit-content;
  &-icon {
    background-color: $n-9;
    height: 36px;
    width: 36px;
    display: inline-block;
    padding: 5px;
    margin: 1px;
  }
}

.radio-button-disabled {
  cursor: default;
  opacity: 0.7;

  &:hover {
    cursor: default;
    opacity: 0.7;
  }
}

.show.dropdown {
  .button {
    color: $n-9;
    background-color: $p-3;
    border-color: $p-3;
    box-shadow: none;

    &:hover,
    &:focus {
      background-color: $p-3;
      border-color: $p-3;
      box-shadow: none;
    }
  }
}

.dropdown {
  .button {
    &:hover,
    &:focus {
      background-color: $p-3;
      border-color: $p-3;
      box-shadow: none;
    }
  }
}

.header__right {
  .dropdown {
    .button {
      height: 50px;
      color: $n-1;
      background-color: transparent !important;
      &:hover,
      &:focus {
        background-color: transparent;
        border-color: $p-3;
        box-shadow: none;
      }
      &::after {
        display: none;
      }
    }
    .dropdown-menu.show {
      inset: 15px auto auto 12px !important;
      min-width: 100%;
    }
  }
}

.text-button {
  color: $n-1;

  &:hover {
    color: $p-2;
    cursor: pointer;
  }
}

.google-btn {
  width: 100%;
  height: 38px;
  background-color: $n-9;
  margin-top: 16px;
  padding: 0px;
  box-shadow: none;
  outline: none;
  border: 1px solid $p-2;
  &:focus {
    box-shadow: none;
    outline: none;
  }
  .google-icon {
    width: 18px;
    height: 18px;
  }
  .btn-text {
    padding: 7px 0px;
    color: $p-2;
    font-size: 14px;
    text-align: center;
    line-height: 22px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
  }
}

.stripe-btn {
  min-width: 80px;
  max-width: fit-content;
  height: 40px;
  line-height: 40px;
  // background-color: #625afa;
  background-color: #6772e5;
  padding: 0px;
  padding-left: 10px;
  padding-right: 10px;
  box-shadow: none;
  outline: none;
  // border: 1px solid #5e57f1;
  box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08);
  color: $n-9;
  -webkit-transition: all 150ms ease;
  transition: all 150ms ease;

  &:hover {
    color: $n-9;
    cursor: pointer;
    background-color: #7795f8;
    transform: translateY(-1px);
    box-shadow: 0 7px 14px rgba(50, 50, 93, 0.1), 0 3px 6px rgba(0, 0, 0, 0.08);
  }
}

.genuin-btn {
  width: 100%;
  height: 38px;
  // background-color: $n-9;
  background-color: #0645ff;
  // margin-top: 16px;
  padding: 0px;
  box-shadow: none;
  outline: none;
  border: 1px solid #0645ff;
  &:focus {
    box-shadow: none;
    outline: none;
  }

  &:hover {
    background-color: #072ebe;
  }

  .genuin-icon {
    width: 20px;
    height: 20px;
  }
  .btn-text {
    padding: 7px 0px;
    // color: #0645ff;
    color: $n-9;
    font-size: 14px;
    text-align: center;
    line-height: 22px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 5px;
  }
}

.copy-icon-btn {
  background-color: transparent;
  outline: none;
  border: none;
}
