.resend {
  &-title {
    font-size: 25px;
  }
  &-subtitle {
    font-size: 20px;
    margin-top: 25px;
    margin-bottom: 5px;
    padding: 8px 12px;
    background-color: #f4f4f4;
  }
  &-details{
    padding: 24px;
    border-radius: 4px;
    box-shadow: 0 0 10px rgba(0,0,0,.05);
  }
  &-row {
    display: flex;
    align-items: center;
  }
  &-column {
    padding: 10px 12px;
    &-thumb{
      width: 35px;
      height: 35;
      object-fit: cover;
      border-radius: 10%;
    }
    &._1 {
      width: 50px;
    }
    &._2 {
      flex: 1;
    }
    &._3 {
      flex: 1;
    }
    &._4 {
      flex: 1;
    }
    &._5 {
      width: 150px;
    }
  }
}
